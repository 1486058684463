import React, { useState } from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import useInvitation from '../../lib/hooks/useInvitation';

function Greetings() {
  const [open, setOpen] = useState(true);
  let { invitee } = useInvitation();

  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      open={open}
      onClose={() => setOpen(false)}
      message={
        <div>
          {invitee && (
            <div>
              Dear {invitee}
              <br />
              <br />
            </div>
          )}
          You are invited to our wedding! <br />
          You are welcome to attend in person or via Google Meet (Wedding
          Reception only, link below, click View Invitation). <br />
          Your prayers and messages are also highly appreciated.
          <br />
          <br />
          Thank You!
          <br />
          Wildan &amp; Aneke
        </div>
      }
      action={
        <React.Fragment>
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={() => setOpen(false)}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        </React.Fragment>
      }
    />
  );
}

export default Greetings;
