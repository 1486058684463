import 'react-responsive-carousel/lib/styles/carousel.min.css';
import './index.css';

import React from 'react';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import { Carousel } from 'react-responsive-carousel';
import format from 'date-fns/format';

import Heart from '../Icons/Heart';
import Decor from '../Icons/Decor';

const useStyles = makeStyles((theme) => ({
  button: {
    width: 150,
  },
}));

const SVGDecor = ({ flipped = false }) => (
  <Decor
    width="281px"
    height="51px"
    flipped={flipped}
    className="shape-decor"
  />
);

function Header({ weddingDate }) {
  const classes = useStyles();

  const headerDate = format(weddingDate, 'yyyy-MM-dd');

  return (
    <div className="header">
      <Carousel
        className="header-carousel-container"
        autoPlay
        infiniteLoop
        showStatus={false}
        showArrows={false}
        showThumbs={false}
      >
        {new Array(5).fill().map((_, i) => (
          <div key={i} className={`carousel-img img${i + 1}`} />
        ))}
      </Carousel>
      <div className="header-overlay" />
      <div className="announcement-wrapper">
        <div className="announcement">
          <div className="heart" data-aos="fade-up">
            <Heart />
          </div>
          <div data-aos="fade-up" data-aos-delay="100">
            <SVGDecor flipped />
          </div>
          <div
            className="title-greetings"
            data-aos="fade-up"
            data-aos-delay="200"
          >
            We're getting married!
          </div>
          <div className="title-name" data-aos="fade-up" data-aos-delay="300">
            <span className="name">Wildan</span>
            <span className="and">&</span>
            <span className="name">Aneke</span>
          </div>
          <div className="title-date" data-aos="fade-up" data-aos-delay="400">
            <span>{headerDate}</span>
          </div>
          <div data-aos="fade-up" data-aos-delay="500">
            <SVGDecor />
          </div>
        </div>
        <div data-aos="fade-up" data-aos-delay="600">
          <Button
            className={classes.button}
            variant="contained"
            color="primary"
            href="#event"
          >
            View Invitation
          </Button>
        </div>
        <div data-aos="fade-up" data-aos-delay="700">
          <Button
            className={classes.button}
            variant="contained"
            color="secondary"
            href="#comments"
          >
            Leave a Message
          </Button>
        </div>
      </div>
    </div>
  );
}

export default Header;
