import React, { useEffect, useRef, useState } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Fab from '@material-ui/core/Fab';
import Zoom from '@material-ui/core/Zoom';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Snackbar from '@material-ui/core/Snackbar';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from '@material-ui/icons/Close';
import ShareIcon from '@material-ui/icons/Share';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import LinkIcon from '@material-ui/icons/Link';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const ShareButton = withStyles((theme) => ({
  root: {
    '& > .MuiButton-label': {
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      width: 48,
      textAlign: 'center',
    },
  },
}))(Button);

const ShareDialog = ({ open, onClose }) => {
  const [name, setName] = useState('');
  const [copyMessage, setCopyMessage] = useState('');
  const urlRef = useRef();

  const shareUrl = `https://aneke.wildan.id${
    name ? `/?invitee=${encodeURIComponent(name)}` : ''
  }`;

  const handleCopyUrl = () => {
    const urlElement = urlRef.current;
    console.dir(urlElement);
    if (!urlElement) {
      return;
    }
    urlElement.focus();
    urlElement.select();

    try {
      const success = document.execCommand('copy');
      if (!success) {
        throw new Error();
      }
      setCopyMessage('Link copied to clipboard');
    } catch (err) {
      setCopyMessage('Error when Copying Link');
    }
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle onClose={onClose}>Share Invitation</DialogTitle>
        <DialogContent>
          <TextField
            value={name}
            autoFocus
            label="Name (optional)"
            helperText="Name of the person to share this invitation to"
            fullWidth
            onChange={(e) => setName(e.target.value)}
          />
          <TextField
            inputRef={urlRef}
            style={{ marginTop: 16 }}
            value={shareUrl}
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <ShareButton
            href={`whatsapp://send?text=${encodeURIComponent(shareUrl)}`}
          >
            <WhatsAppIcon style={{ color: '#25d366' }} />
            <Typography variant="caption">Share Via Whatsapp</Typography>
          </ShareButton>
          <ShareButton onClick={handleCopyUrl}>
            <LinkIcon />
            <Typography variant="caption">Copy Link</Typography>
          </ShareButton>
        </DialogActions>
      </Dialog>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        open={Boolean(copyMessage)}
        autoHideDuration={3000}
        onClose={() => setCopyMessage('')}
        message={copyMessage}
      />
    </>
  );
};

const useStyles = makeStyles(() => ({
  shareBtn: {
    bottom: 0,
    right: 0,
    margin: 16,
    zIndex: 5,
    position: 'fixed',
  },
}));

function Share() {
  const classes = useStyles();
  const [showFab, setShowFab] = useState(false);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > window.screen.height) {
        setShowFab(true);
      } else {
        setShowFab(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <div>
      <Zoom in={showFab}>
        <Fab
          className={classes.shareBtn}
          color="primary"
          onClick={() => setShowModal(true)}
        >
          <ShareIcon />
        </Fab>
      </Zoom>
      <ShareDialog open={showModal} onClose={() => setShowModal(false)} />
    </div>
  );
}

export default Share;
