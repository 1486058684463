import React from 'react';
import { Grid, Typography, IconButton } from '@material-ui/core';
import Title from '../Title';
import { Instagram, LinkedIn, Mail } from '@material-ui/icons';

const CREDITS = [
  {
    name: 'Wildan Maulana Syahidillah',
    role: 'Software Engineer',
    instagramUrl: 'https://www.instagram.com/wsyahidillah/',
    linkedinUrl: 'https://www.linkedin.com/in/wildan-syahidillah-a83990128/',
    email: 'wildan2711@gmail.com',
  },
  {
    name: 'Aneke Deborah',
    role: 'Software Engineer',
    instagramUrl: 'https://www.instagram.com/aneke.deborah/',
    linkedinUrl: 'https://www.linkedin.com/in/aneke-deborah-28b362129/',
    email: 'aneke.deborah@gmail.com',
  },
  {
    name: 'Ilham Rianto',
    role: 'Photography',
    instagramUrl: 'https://www.instagram.com/ilhamrianto7/',
    linkedinUrl: 'https://www.linkedin.com/in/ilhamrianto7/',
    email: 'ilhamrianto7@gmail.com',
  },
];

function Credits() {
  return (
    <div style={{ padding: '32px 24px' }}>
      <Grid container justify="center">
        <Grid item xs={12} sm={8} md={6}>
          <Title>CREDITS</Title>
          <Grid
            container
            spacing={2}
            style={{ textAlign: 'center' }}
            justify="center"
          >
            {CREDITS.map((credit) => (
              <Grid key={credit.name} item xs={12} sm={6} lg={4}>
                <Typography variant="h6">{credit.name}</Typography>
                <Typography variant="caption">{credit.role}</Typography>
                <div>
                  <IconButton
                    color="default"
                    style={{ padding: 4 }}
                    href={credit.instagramUrl}
                    target="_blank"
                  >
                    <Instagram />
                  </IconButton>
                  <IconButton
                    color="default"
                    style={{ padding: 4 }}
                    href={credit.linkedinUrl}
                    target="_blank"
                  >
                    <LinkedIn />
                  </IconButton>
                  <IconButton
                    color="default"
                    style={{ padding: 4 }}
                    href={`mailto:${credit.email}`}
                    target="_blank"
                  >
                    <Mail />
                  </IconButton>
                </div>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}

export default Credits;
