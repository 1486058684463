import React from 'react';
import DecorSVG from '../../assets/img/decor.svg';

const Decor = ({
  flipped,
  height = '35px',
  width = '300px',
  className = 'shape-mini-decor',
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 281 51"
    style={flipped ? { transform: 'rotate(180deg)' } : {}}
  >
    <use className={className} xlinkHref={`${DecorSVG}#shape-decor`}></use>
  </svg>
);

export default Decor;
