import './index.css';
import React from 'react';
import Grid from '@material-ui/core/Grid';

function QrCode() {
  return (
    <div>
      <Grid container spacing={2} wrap justify="center">
        <Grid item xs={12} sm={5} justify="center" className="qrcode-container">
          <h4 className="qrcode-text">BCA</h4>
          <img
            src="/img/BCAQR-MBC.jpg"
            alt="bca"
            className="portrait"
            data-aos="fade-up"
            style={{ width: '300px', height: '300px' }}
          />
        </Grid>
        <Grid item xs={12} sm={5} justify="center" className="qrcode-container">
          <h4 className="qrcode-text">OVO</h4>
          <img
            src="/img/ovo.jpg"
            alt="ovo"
            className="portrait"
            data-aos="fade-up"
            style={{ width: '250px', height: '250px' }}
          />
        </Grid>
      </Grid>
    </div>
  );
}

export default QrCode;
