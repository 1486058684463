import './App.css';
import 'aos/dist/aos.css';
import { useEffect } from 'react';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { ApolloClient, InMemoryCache } from '@apollo/client';
import { ApolloProvider } from '@apollo/client/react';
import { offsetLimitPagination } from '@apollo/client/utilities';
import AOS from 'aos';

import useNavScroller from './lib/hooks/useNavScroller';

import Header from './components/Header';
import Navigation from './components/Navigation';
import Comments from './components/Comments';
import Story from './components/Story';
import Events from './components/Events';
import Couple from './components/Couple';
import Credits from './components/Credits';
import CountDown from './components/CountDown';
import Share from './components/Share';
import Greetings from './components/Greetings';
import Separator from './components/Separator';
import QrCode from './components/QrCode';

const theme = createMuiTheme({
  typography: {
    fontFamily: '"Fira Sans",sans-serif',
  },
  palette: {
    primary: {
      main: '#e86c6c',
      light: '#ff9d9a',
      dark: '#b13c41',
      contrastText: '#ffffff',
    },
    secondary: {
      main: '#fcf7ff',
      light: '#ffffff',
      dark: '#c9c4cc',
      contrastText: '#000000',
    },
  },
});

const client = new ApolloClient({
  uri: 'https://purple-sunset.ap-south-1.aws.cloud.dgraph.io/graphql',
  cache: new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          queryComment: offsetLimitPagination(),
        },
      },
    },
  }),
});

const weddingDate = new Date(2021, 5, 6, 9);

const Wrapper = ({ children }) => (
  <ApolloProvider client={client}>
    <MuiThemeProvider theme={theme}>{children}</MuiThemeProvider>
  </ApolloProvider>
);

function App() {
  useEffect(() => {
    AOS.init({
      once: true,
    });
  }, []);

  useNavScroller();

  return (
    <Wrapper>
      <Header weddingDate={weddingDate} />
      <Greetings />
      <Navigation />
      <Share />
      <section
        id="couple"
        style={{
          padding: '7rem 0 2rem',
          margin: '0.2rem 0',
          overflow: 'hidden',
          position: 'relative',
        }}
      >
        <Couple />
      </section>
      <div style={{ padding: '60px 0 40px' }}>
        <Separator />
      </div>
      <section id="story">
        <Story />
      </section>
      <section style={{ padding: '30px 0' }}>
        <CountDown weddingDate={weddingDate} />
      </section>
      <section id="event" className="event-section">
        <Events />
      </section>
      {/* <section style={{ overflow: 'hidden', paddingBottom: '30px' }}>
        <QrCode />
      </section> */}
      <section id="comments">
        <Comments />
      </section>
      <section id="credits">
        <Credits />
      </section>
    </Wrapper>
  );
}

export default App;
