import './index.css';
import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import EventIcon from '@material-ui/icons/Event';
import AccessAlarmIcon from '@material-ui/icons/AccessAlarm';
import RoomIcon from '@material-ui/icons/Room';
import MapIcon from '@material-ui/icons/Map';
import VideoCallIcon from '@material-ui/icons/VideoCall';

import Title from '../Title';
import useInvitation from '../../lib/hooks/useInvitation';

const CALENDAR_CEREMONY_URL =
  'https://calendar.google.com/event?action=TEMPLATE&tmeid=MDJsbjBscTJuYjlsOXUzbnJsZG5sMTVqcjAgdnBkbW1naWxuZTRjc3FjbDhwYXVidHEycnNAZw&tmsrc=vpdmmgilne4csqcl8paubtq2rs%40group.calendar.google.com';
const CALENDAR_RECEPTION_URL =
  'https://calendar.google.com/event?action=TEMPLATE&tmeid=NTVlMzliODNlbzduMmdxdnBlaWhvOXBxcXMgdnBkbW1naWxuZTRjc3FjbDhwYXVidHEycnNAZw&tmsrc=vpdmmgilne4csqcl8paubtq2rs%40group.calendar.google.com';
const DIRECTIONS_URL = 'https://goo.gl/maps/k2LSCupEySdBeygt6';

const EVENTS = [
  {
    title: 'Wedding Ceremony',
    description:
      'Muslim marriage ceremony with Ijab Qabul and payment of Mahr from the groom to the bride.',
    time: '9AM - Finish',
    imgClass: 'event-thumbnail-img1',
    calendarUrl: CALENDAR_CEREMONY_URL,
  },
  {
    title: 'Wedding Reception',
    description:
      'Wedding reception with Javanese traditional ceremonies including Panggih Ceremony, Balangan Gantal, etc.',
    time: '10AM - Finish',
    imgClass: 'event-thumbnail-img2',
    calendarUrl: CALENDAR_RECEPTION_URL,
    meetUrl: 'https://meet.google.com/odm-bttd-gqe',
  },
];

const AddToCalendarDialog = ({ href, open, onClose }) => (
  <Dialog
    open={open}
    onClose={onClose}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
  >
    <DialogTitle>Add to Calendar</DialogTitle>
    <DialogContent>
      <DialogContentText id="alert-dialog-description">
        Please open using your web browser. Do not use the Google Calendar app.
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button
        onClick={() => {
          window.open(href);
          onClose();
        }}
        color="primary"
        autoFocus
      >
        Open
      </Button>
    </DialogActions>
  </Dialog>
);

function Events() {
  const theme = useTheme();
  const [openATC, setOpenATC] = useState(false);
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { invitee } = useInvitation();

  return (
    <div>
      <Title>EVENTS</Title>
      <div className="event-section-intro">
        {invitee && (
          <div>
            To: <h3 style={{ marginBottom: '0.1em' }}>{invitee}</h3>
          </div>
        )}
        <p className="event-section-intro-p">
          <br />
          You have been invited to our special day:
        </p>
      </div>
      {EVENTS.map((event, i) => (
        <React.Fragment key={event.title}>
          <AddToCalendarDialog
            href={event.calendarUrl}
            open={openATC}
            onClose={() => setOpenATC(false)}
          />
          <Grid container wrap justify="center">
            <Grid item xs={12} justify="center" className="event-list">
              <div className={`event-thumbnail ${event.imgClass}`} />
            </Grid>
            <Grid item xs={12} md={6}>
              <div className="event-details">
                <h3 className="event-details-title" data-aos="fade-up">
                  {event.title}
                </h3>
                <ul className="event-info-ul">
                  <li
                    className="event-info"
                    data-aos="fade-up"
                    data-aos-delay="100"
                  >
                    <span className="event-info-span">
                      <RoomIcon />
                    </span>
                    Jl. Raya Balen - Sugihwaras, Ds. Jumput, Kab. Bojonegoro (Bu
                    Mahmud Pertigaan Kalipan)
                  </li>
                  <li data-aos="fade-up" data-aos-delay="200">
                    <span className="event-info-span">
                      <AccessAlarmIcon />
                    </span>
                    {event.time}, June 6, 2021
                  </li>
                </ul>
                <div
                  className="event-details-description"
                  data-aos="fade-up"
                  data-aos-delay="300"
                >
                  <p className="event-section-intro-p">{event.description}</p>
                </div>
                <div data-aos="fade-up" data-aos-delay="400">
                  <Button
                    variant="contained"
                    color="primary"
                    target="_blank"
                    startIcon={<EventIcon />}
                    fullWidth={isMobile}
                    onClick={() => setOpenATC(true)}
                  >
                    Add to Calendar
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    className="event-directions"
                    href={DIRECTIONS_URL}
                    target="_blank"
                    startIcon={<MapIcon />}
                    fullWidth={isMobile}
                  >
                    Google Map Directions
                  </Button>
                  {event.meetUrl && (
                    <Button
                      variant="contained"
                      color="primary"
                      className="event-directions"
                      href={event.meetUrl}
                      target="_blank"
                      startIcon={<VideoCallIcon />}
                      fullWidth={isMobile}
                    >
                      Google Meet
                    </Button>
                  )}
                </div>
              </div>
            </Grid>
          </Grid>
          {i !== EVENTS.length - 1 && (
            <Grid container justify="center">
              <div className="event-list-li-2" />
            </Grid>
          )}
        </React.Fragment>
      ))}
    </div>
  );
}

export default Events;
