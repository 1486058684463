import 'react-responsive-carousel/lib/styles/carousel.min.css';
import './index.css';

import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

function Separator() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <div className="separator">
      <Carousel
        className="separator-carousel-container"
        autoPlay
        infiniteLoop
        centerMode
        centerSlidePercentage={isMobile ? 100 : 50}
        showStatus={false}
        showThumbs={false}
      >
        {new Array(5).fill().map((_, i) => (
          <div className={`separator-carousel-img carousel-img${i + 1}`}>
            <div className="separator-carousel-img-overlay" />
          </div>
        ))}
      </Carousel>
    </div>
  );
}

export default Separator;
