import './index.css';
import { Parallax } from 'react-parallax';
import image from '../../assets/img/parallax-min.jpg';
import Counter from './Counter';

function CountDown({ weddingDate }) {
  return (
    <div>
      <Parallax bgImage={image} strength={500}>
        <div style={{ height: 400, position: 'relative' }}>
          <div className="image-overlay">
            <div className="flower-white" />
            <div className="parallax-styles">Counting Down to Our Big Day</div>
            <div className="parallax-styles-ul">
              {' '}
              <Counter endDate={weddingDate} />
            </div>
          </div>
        </div>
      </Parallax>
    </div>
  );
}

export default CountDown;
