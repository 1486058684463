import './index.css';
import React from 'react';
import Heart from '../Icons/Heart';
import Title from '../Title';

function Story() {
  return (
    <div>
      <Title>STORY</Title>
      <ul className="timeline">
        <div className="line" />
        <li className="timeline-li">
          <div className=" timeline-meta meta">
            <div className="story-section story-section-img1" />
            <div className="timeline-date">2014</div>
          </div>
          <div className="timeline-heart">
            <Heart />
          </div>
          <div className="timeline-description">
            <p className="timeline-description-p" data-aos="fade">
              The first time we met was rather unromantic. We met as Assistant
              Laboratories in University, Aneke was the popular Lab Secretary
              Coordinator and Wildan was just a nerdy and quiet new member. We
              barely noticed each other at that time. 😂
            </p>
          </div>
        </li>
        <li className="timeline-li-reverse">
          <div className=" timeline-meta-reverse meta">
            <div className="story-section-reverse story-section-img2" />
            <div className="timeline-date-reverse">2017</div>
          </div>
          <div className="timeline-heart">
            <Heart />
          </div>
          <div className="timeline-description-reverse">
            <p className="timeline-description-p" data-aos="fade">
              We somehow met again when working for a company in Malang, Aneke
              was an existing employee and Wildan was the potential new star
              employee. The sad part was Aneke initially forgot who Wildan was
              when we met again. 😢
            </p>
          </div>
        </li>
        <li className="timeline-li">
          <div className=" timeline-meta meta">
            <div className="story-section story-section-img3" />
            <div className="timeline-date">2018</div>
          </div>
          <div className="timeline-heart">
            <Heart />
          </div>
          <div className="timeline-description">
            <p className="timeline-description-p" data-aos="fade">
              For a while, we were just friends. But every little things that
              happened, the happy memories, the trials and tribulations, made us
              understand one another more. 🦾
            </p>
          </div>
        </li>
        <li className="timeline-li-reverse">
          <div className=" timeline-meta-reverse meta">
            <div className="story-section-reverse story-section-img4" />
            <div className="timeline-date-reverse">2019</div>
          </div>
          <div className="timeline-heart">
            <Heart />
          </div>
          <div className="timeline-description-reverse">
            <p className="timeline-description-p" data-aos="fade">
              Wildan left to work in Jakarta, which separated us, testing the
              strength of our friendship. 😢
            </p>
          </div>
        </li>
        <li className="timeline-li">
          <div className=" timeline-meta meta">
            <div className="story-section story-section-img5" />
            <div className="timeline-date">2020</div>
          </div>
          <div className="timeline-heart">
            <Heart />
          </div>
          <div className="timeline-description">
            <p className="timeline-description-p" data-aos="fade">
              Then the Covid-19 pandemic happened. The world was crumbling, a
              lot of people are sick and dying. We had to work from home. What
              we didn't know is that it brought us closer together.
            </p>
          </div>
        </li>
        <li className="timeline-li-reverse">
          <div className=" timeline-meta-reverse meta">
            <div className="story-section-reverse story-section-img6" />
            <div className="timeline-date-reverse">2021</div>
          </div>
          <div className="timeline-heart">
            <Heart />
          </div>
          <div className="timeline-description-reverse">
            <p className="timeline-description-p" data-aos="fade">
              There was nothing special about our story, but it made us
              understand that Allah SWT always shows us how to be together and
              complement each other. Finally, we decided to spend our life
              together. 💑
            </p>
          </div>
        </li>
      </ul>
    </div>
  );
}

export default Story;
