import './index.css';
import React, { useState, useRef, useCallback } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useApolloClient, useQuery } from '@apollo/client/react';
import { gql } from '@apollo/client';
import { formatDistanceToNow, parseISO } from 'date-fns';
import debounce from 'lodash/debounce';

import Title from '../Title';

const ThankYouDialog = ({ open, onClose }) => (
  <Dialog
    open={open}
    onClose={onClose}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
  >
    <DialogTitle>Thank You</DialogTitle>
    <DialogContent>
      <DialogContentText id="alert-dialog-description">
        Thank you for the message!
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={onClose} color="primary" autoFocus>
        Close
      </Button>
    </DialogActions>
  </Dialog>
);

const Comment = ({ comment }) => (
  <Paper style={{ padding: '30px 20px' }}>
    <h4 style={{ margin: 0 }}>{comment.senderName}</h4>
    <Typography variant="caption" style={{ color: 'gray' }}>
      {formatDistanceToNow(parseISO(comment.sentTime), {
        addSuffix: true,
      })}
    </Typography>
    <p>{comment.message}</p>
  </Paper>
);

function Comments() {
  const client = useApolloClient();
  const [name, setName] = useState('');
  const [message, setMessage] = useState('');
  const [errorMessages, setErrorMessages] = useState({});
  const [openTYDialog, setOpenTYDialog] = useState(false);
  const pagination = useRef({
    page: 1,
    hasMore: true,
  });
  const pageSize = 9;
  const { refetch, data, loading, fetchMore } = useQuery(
    gql`
      query comments($offset: Int, $limit: Int) {
        queryComment(
          order: { desc: sentTime }
          offset: $offset
          first: $limit
        ) {
          id
          senderName
          message
          sentTime
        }
      }
    `,
    {
      fetchPolicy: 'network-only',
      variables: {
        offset: 0,
        limit: pageSize,
      },
      notifyOnNetworkStatusChange: true,
    }
  );
  const comments = data?.queryComment || [];

  const validateInput = () => {
    const newErrorMessages = {};
    let error = false;
    if (!name) {
      newErrorMessages.name = 'Please fill in your name';
      error = true;
    }
    if (!message) {
      newErrorMessages.message = 'Please fill in your message';
      error = true;
    }
    if (error) {
      setErrorMessages(newErrorMessages);
    } else {
      setErrorMessages({});
    }
    return error;
  };

  const handleSave = async (e) => {
    e.preventDefault();

    const isError = validateInput();
    if (isError) {
      return;
    }

    await client.mutate({
      mutation: gql`
        mutation addComment($input: [AddCommentInput!]!) {
          addComment(input: $input) {
            numUids
          }
        }
      `,
      variables: {
        input: [
          { senderName: name, message, sentTime: new Date().toISOString() },
        ],
      },
    });
    await refetch({
      offset: 0,
    });
    setOpenTYDialog(true);
    setName('');
    setMessage('');
  };

  const handleCommentScroll = useCallback(
    debounce((e) => {
      const loadMoreOffset = 152;
      const node = e.target;
      const loadMore =
        node.scrollTop + node.offsetHeight > node.scrollHeight - loadMoreOffset;
      if (!loadMore || !pagination.current.hasMore) {
        return;
      }
      pagination.current.page++;
      fetchMore({
        variables: {
          offset: (pagination.current.page - 1) * pageSize,
        },
      }).then((fetchMoreResult) => {
        if (!fetchMoreResult.data.queryComment?.length) {
          pagination.current.hasMore = false;
        }
      });
    }, 100),
    [fetchMore]
  );

  return (
    <div className="comment-container">
      <ThankYouDialog
        open={openTYDialog}
        onClose={() => setOpenTYDialog(false)}
      />
      <Title>MESSAGES</Title>
      <form>
        <Grid container spacing={2} justify="center">
          <Grid item xs={12} md={6}>
            <Typography align="center">
              Your prayers and messages for this marriage is greatly appreciated
              and hopefully be remembered until the end of our lives. We pray
              your kindness is returned by Allah SWT.
            </Typography>
            <TextField
              value={name}
              disabled={loading}
              label="Name / Nama"
              fullWidth
              margin="normal"
              required
              error={Boolean(errorMessages.name)}
              helperText={errorMessages.name}
              onChange={(e) => setName(e.target.value)}
            />
            <TextField
              value={message}
              disabled={loading}
              label="Your Prayers and Messages / Doa dan Pesan-Pesan"
              multiline
              rows={4}
              fullWidth
              margin="normal"
              required
              error={Boolean(errorMessages.message)}
              helperText={errorMessages.message}
              onChange={(e) => setMessage(e.target.value)}
            />
            <Box display="flex" justifyContent="flex-end">
              <Button
                type="submit"
                variant="contained"
                color="primary"
                onClick={handleSave}
              >
                Send
              </Button>
            </Box>
            <div style={{ position: 'relative' }}>
              {loading && (
                <div className="comment-list-loading-container">
                  <CircularProgress color="primary" thickness={5.4} />
                </div>
              )}
              <Grid
                container
                spacing={2}
                className="comment-list-container"
                onScroll={handleCommentScroll}
              >
                {comments.map((comment) => (
                  <Grid key={comment.id} item xs={12} sm={6} md={4}>
                    <Comment comment={comment} />
                  </Grid>
                ))}
              </Grid>
            </div>
          </Grid>
        </Grid>
      </form>
    </div>
  );
}

export default Comments;
