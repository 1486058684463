import './index.css';
import React from 'react';
import Decor from '../Icons/Decor';

function Title({ children }) {
  return (
    <div className="title-body">
      <Decor flipped />
      <div style={{ margin: '0 0 5px' }}>{children}</div>
      <Decor />
    </div>
  );
}

export default Title;
