import React from 'react';
import HeartSVG from '../../assets/img/heart.svg';

export default function Heart({ width = '38px', height = '35px' }) {
  return (
    <svg width={width} height={height} viewBox="0 0 38 35">
      <use class="shape-heart" xlinkHref={`${HeartSVG}#shape-heart`}></use>
    </svg>
  );
}
