import React, { useState, useEffect, useCallback } from 'react';
import Button from '@material-ui/core/Button';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Collapse from '@material-ui/core/Collapse';
import CloseIcon from '@material-ui/icons/Close';
import MenuIcon from '@material-ui/icons/Menu';

import Heart from '../Icons/Heart';

const NAV_ITEMS = [
  {
    text: 'HOME',
    href: '#',
  },
  {
    text: 'COUPLE',
    href: '#couple',
  },
  {
    text: 'STORY',
    href: '#story',
  },
  {
    text: 'EVENT',
    href: '#event',
  },
  {
    text: 'MESSAGES',
    href: '#comments',
  },
];

const useStyles = makeStyles((theme) => ({
  appBar: {
    backgroundColor: 'rgba(255, 255, 255, 0.9)',
  },
  menu: {
    position: 'relative',
    display: 'block',
    justifyContent: 'center',
    boxShadow: '0 3px 3px rgb(0 0 0 / 5%)',
    [theme.breakpoints.up('sm')]: {
      '& .MuiCollapse-wrapperInner': {
        display: 'flex',
        justifyContent: 'center',
      },
    },
  },
  menuButton: {
    color: '#4a4a4a',
    fontSize: '14px',
    fontWeight: '700',
    margin: '16px',
    [theme.breakpoints.down('xs')]: {
      display: 'block',
      textAlign: 'center',
      width: '100%',
      margin: '8px 0',
    },
  },
  menuButtonActive: {
    color: theme.palette.primary.main,
  },
  menuButtonHeart: {
    position: 'absolute',
    top: 0,
    left: 'calc(50% - 6px)',
    marginTop: -8,
    [theme.breakpoints.up('sm')]: {
      marginTop: 8,
    },
  },
}));

function Navigation() {
  const classes = useStyles();
  const theme = useTheme();
  const [activeItem, setActiveItem] = useState({
    href: '',
    text: '',
  });
  const [openMenu, setOpenMenu] = useState(false);

  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));

  const handleScroll = useCallback(() => {
    let currentItem = NAV_ITEMS[0];

    for (const navItem of NAV_ITEMS) {
      const element = document.getElementById(navItem.href.replace('#', ''));
      if (!element) {
        continue;
      }
      if (element.offsetTop - 48 > window.scrollY) {
        break;
      }
      currentItem = navItem;
    }

    setActiveItem(currentItem);
  }, []);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll]);

  const isActiveItem = (item) => item.href === activeItem.href;

  const buttonClassName = (item) => {
    const classNames = [classes.menuButton];
    if (isActiveItem(item)) {
      classNames.push(classes.menuButtonActive);
    }
    return classNames.join(' ');
  };

  const showButtons = isMobile ? openMenu : true;

  return (
    <AppBar position="sticky" className={classes.appBar}>
      <Toolbar className={classes.menu}>
        {isMobile && (
          <Button
            fullWidth
            style={{ minHeight: 56 }}
            onClick={() => setOpenMenu(!openMenu)}
          >
            {openMenu ? <CloseIcon /> : <MenuIcon />}
          </Button>
        )}
        <Collapse in={showButtons}>
          {NAV_ITEMS.map((item) => (
            <div style={{ position: 'relative' }} key={item.href}>
              {isActiveItem(item) && (
                <div className={classes.menuButtonHeart}>
                  <Heart width="12px" height="12px" />
                </div>
              )}
              <Button href={item.href} className={buttonClassName(item)}>
                {item.text}
              </Button>
            </div>
          ))}
        </Collapse>
      </Toolbar>
    </AppBar>
  );
}

export default Navigation;
