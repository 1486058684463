import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

function useNavScroller() {
  const { hash } = useLocation();

  useEffect(() => {
    const element = document.getElementById(hash.replace('#', ''));

    if (element) {
      window.scrollTo({
        behavior: 'smooth',
        top: element.offsetTop - 48,
      });
    } else {
      window.scrollTo(0, 0);
    }
  }, [hash]);
}

export default useNavScroller;
