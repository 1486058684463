import './index.css';
import React from 'react';
import Grid from '@material-ui/core/Grid';
import Title from '../Title';

function Couple() {
  return (
    <div>
      <div className="flower" />
      <Title>COUPLE</Title>
      <div className="couple-intro">
        <p className="couple-intro-body">
          “And one of His signs is that He created mates for you from yourselves
          that you may find rest in them, and He put between you love and
          compassion; most surely there are signs in this for people who
          reflect.” <br />
          QS. Ar-Rum Verse 21
        </p>
      </div>
      <Grid container spacing={3} wrap justify="center">
        <Grid item xs={12} sm={5} justify="center" className="couple-container">
          <img
            src="/img/wildan.jpg"
            alt="groom"
            className="portrait"
            data-aos="fade-up"
          />
          <h3 className="couple-text">Wildan Maulana Syahidillah, S.Kom</h3>
          <h4 className="couple-subtext">Groom</h4>
          <p className="couple-description">
            Son of <br />
            <b>Dr. H. Sugeng Rianto, M.Sc</b> <br />
            &amp; <br />
            <b>Hj. Dyah Handari Wanti, S.Si</b>
          </p>
        </Grid>
        <Grid item xs={1} className="couple-and">
          &
        </Grid>
        <Grid item xs={12} sm={5} justify="center" className="couple-container">
          <img
            src="/img/aneke.jpg"
            alt="bride"
            className="portrait"
            data-aos="fade-up"
          />
          <h3 className="couple-text">Aneke Deborah, S.Kom</h3>
          <h4 className="couple-subtext">Bride</h4>
          <p className="couple-description">
            Daughter of <br />
            <b>Mahmudi Ilyas, S.H (Alm.)</b> <br />
            &amp; <br />
            <b>Widi Asih</b>
          </p>
        </Grid>
      </Grid>
    </div>
  );
}

export default Couple;
