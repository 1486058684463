import { parse } from 'querystring';
import { useLocation } from 'react-router';

function useInvitation() {
  const { search } = useLocation();
  const { invitee = '' } = parse(search.slice(1));

  return {
    invitee,
  };
}

export default useInvitation;
